import React, { useEffect, useState, useCallback } from 'react';
import {
    CandlestickSeries,
    CrossHairCursor,
    MouseCoordinateX,
    MouseCoordinateY,
    OHLCTooltip,
    CurrentCoordinate,
    ChartCanvas, 
    Chart,
    discontinuousTimeScaleProvider,
} from 'react-financial-charts';
import { timeFormat } from 'd3-time-format';
import { format } from 'd3-format';
import axios from 'axios';
import './CryptoChart.css';

const CryptoChart = ({ fromCurrency, toCurrency, timeFrame, onTimeFrameChange }) => {
    const [chartData, setChartData] = useState([]);

    // دالة لجلب بيانات الشارت بناءً على الإطار الزمني والعملات
    const fetchChartData = useCallback(async () => {
        try {
            const symbol = `${fromCurrency}${toCurrency}`.toUpperCase();
            const response = await axios.get(
                `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${timeFrame}&limit=30`
            );
    
            if (response.data && response.data.length) {
                const formattedData = response.data.map(item => ({
                    date: new Date(item[0]),
                    open: parseFloat(item[1]),
                    high: parseFloat(item[2]),
                    low: parseFloat(item[3]),
                    close: parseFloat(item[4]),
                })).filter(d => d.open && d.high && d.low && d.close); // فلترة البيانات للتأكد من القيم
                setChartData(formattedData);
            } else {
                console.error('Received empty or invalid data from API.');
            }
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    }, [fromCurrency, toCurrency, timeFrame]);
    

    // تحديث البيانات عند تغيير الإطار الزمني أو العملات
    useEffect(() => {
        fetchChartData();
    }, [fetchChartData]);

    if (!chartData || chartData.length === 0) {
        return <div>Loading chart data...</div>; // يمكنك تحسين هذه الرسالة بإضافة مؤشر تحميل
    }

    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => d.date);
    const { data: processedData, xScale, xAccessor, displayXAccessor } = xScaleProvider(chartData);

    // التحقق من صحة البيانات قبل استخدامها في المكونات
    if (!processedData.length) {
        return <div>No valid chart data available.</div>;
    }

    const xExtents = [xAccessor(processedData[0]), xAccessor(processedData[processedData.length - 1])];

    return (
        <div className="chart-wrapper">
            <div className="chart-header">
                <h3 className="chart-title">{fromCurrency} / {toCurrency}</h3>
                <div className="timeframe-buttons">
                    {["15m", "1H", "4H", "1D", "1W"].map((tf) => (
                        <button
                            key={tf}
                            className={`timeframe-button ${timeFrame === tf ? "active" : ""}`}
                            onClick={() => onTimeFrameChange(tf)}
                        >
                            {tf}
                        </button>
                    ))}
                </div>
            </div>
            <div className="chart-container">
                <ChartCanvas
                    height={400}
                    width={650}
                    ratio={1}
                    margin={{ left: 5, right: 10, top: 30, bottom: 30 }}
                    data={processedData}
                    seriesName={`${fromCurrency}-${toCurrency}`}
                    xAccessor={xAccessor}
                    displayXAccessor={displayXAccessor}
                    xScale={xScale}
                    xExtents={xExtents}
                >
                    <Chart id={1} yExtents={(d) => [d.high, d.low]}>
                        <CandlestickSeries
                            wickStroke={(d) => (d.close > d.open ? "#00c176" : "#cf304a")}
                            fill={(d) => (d.close > d.open ? "#00c176" : "#cf304a")}
                            candleStrokeWidth={1}
                            widthRatio={0.5}
                            stroke={(d) => (d.close > d.open ? "#00c176" : "#cf304a")}
                        />

                        <CurrentCoordinate
                            yAccessor={(d) => d.close}
                            fill="#ffbb00"
                        />

                        <MouseCoordinateY
                            at="right"
                            orient="right"
                            displayFormat={format(".2f")}
                            dx={4}
                        />

                        <MouseCoordinateX
                            at="bottom"
                            orient="bottom"
                            displayFormat={timeFormat("%d/%m %H:%M")}
                        />

                        <OHLCTooltip origin={[-40, 0]} textFill="#cccccc" labelFill="#cccccc" />
                    </Chart>
                    <CrossHairCursor />
                </ChartCanvas>
            </div>
        </div>
    );
};

export default CryptoChart;
